import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as deepmerge from 'deepmerge';

import enPui from '@Pui9Translations/translations/en.json';
import esPui from '@Pui9Translations/translations/es.json';
import frPui from '@Pui9Translations/translations/fr.json';
import caPui from '@Pui9Translations/translations/ca.json';

import enApp from './en.json';
import esApp from './es.json';
import frApp from './fr.json';
import caApp from './ca.json';

const en = deepmerge.all([enPui, enApp]);
const es = deepmerge.all([esPui, esApp]);
const fr = deepmerge.all([frPui, frApp]);
const ca = deepmerge.all([caPui, caApp]);

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	silentFallbackWarn: true,
	messages: { en: en, es: es, fr: fr, ca: ca }
});
